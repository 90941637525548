<template>
    <div class="flex flex-col justify-between pb-4 h-full px-4">
        <div>
            <HomeStep2Calendar />
            <HomeStep2Time v-if="!homeStore.loader" />
        </div>
        <UIBtnRed @click="handleSubmit" text="Далее" v-if="homeStore.select.time"/>
    </div>
</template>

<script>
import { useHome } from "~/store/home";

export default {
    data() {
        return {};
    },
    setup() {
        const homeStore = useHome();

        return {
            homeStore,
        };
    },
    async mounted() {
        this.homeStore.loader = true;
        await this.homeStore.createCalendar();
        setTimeout(() => {
            this.homeStore.loader = false;
        }, 2000);
    },
    methods: {
        async handleSubmit() {
            this.homeStore.nav.currentStep++;
        }
    }
};
</script>

